import { encryptPayload } from './CipherHandler';
export class AuthService {
	private readonly baseUrl: string

	constructor() {
		this.baseUrl = `${process.env.REACT_APP_API_URL}/accounts`
	}

	getUserDataByInvite(invite: string, accountId: string): Promise<any> {
		return fetch(`${this.baseUrl}/validateInvite`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify({
				validateId: invite,
				accountId: accountId,
			}),
		})
			.then(res => {
				if (res.ok) return res.json();
				else return Promise.reject(res);
			})
			.catch(error => Promise.reject(error))
	}

	accountSignUp(form: any): Promise<any> {
		return fetch(`${this.baseUrl}/register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify(form),
		})
			.then(res => {
				if (res.ok) return res.ok;
				else return res.json();
			})
			.then(json => {
				if (typeof json !== 'boolean') throw json;
				return json;
			})
			.catch(error => Promise.reject(error))
	}

	publicAccountSignup(form: any): Promise<any> {
		return fetch(`${this.baseUrl}/PublicRegister`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: "include",
			body: JSON.stringify(form)
		})
			.then(res => {
				if (res.ok) return res.text();
				return res.json();
			})
			.then(json => {
				if (json.message || json.errors) {
					throw json;
				}
				return json;
			})
			.catch(error => Promise.reject(error))
	}

	confirmAccount(id: string, token: string): Promise<any> {
		return fetch(`${this.baseUrl}/confirmAccount/${id}/${token}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include',
		})
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}

	accountSignIn(credentials: any): Promise<any> {
		const { userName, password, captchaToken } = credentials;		
		const payload = encryptPayload({ userName, password, captchaToken });

		return fetch(`${this.baseUrl}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify({
				payload
			}),
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.text()
				else return res.json();
			})
			.then(json => {
				if (json.message) {
					throw new Error(
						json.message, 
						{ 
							cause:{ 
								attempts: json.attempts ? json.attempts : 0 
							}
						});
				}
				return json;
			})
			.catch(error => Promise.reject(error))
	}

	accountImpersonatedSignIn(token: string): Promise<any> {

		return fetch(`${this.baseUrl}/LoginImpersonation`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify({ token }),
			credentials: 'include',
		})
			.then(res => {
				if (res.ok) return res.text()
				else return res.json();
			})
			.then(json => {
				if (json.message) {
					throw new Error(json.message);
				}
				return json;
			})
			.catch(error => Promise.reject(error))
	}

	accountSignOut(): Promise<any> {
		return fetch(`${this.baseUrl}/Logout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
			.catch(error => console.log("error loging out", error))
	}

	forgotPassword(email: string): Promise<any> {
		return fetch(`${this.baseUrl}/ResetPassword/${email}`, {
			method: 'POST',
		})
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}

	resetPassword(id: string, password: string, token: string): Promise<any> {
		return fetch(`${this.baseUrl}/confirmResetPassword/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify({
				token,
				password,
			}),
		})
			.then(res => {
				if (res.status === 200) return res.status;
				else if (res.status === 404) return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	resendVerificationEmail(email: any): Promise<any> {
		return fetch(`${this.baseUrl}/ResendActivationEmail/${email}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;',
				'Access-Control-Allow-Credentials': 'true',
			},
		})
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}
}
